import { Button } from '@boltenergy-be/design-system'
import Card from 'components/card/Card'
import FixedPriceDrawer from 'components/simulation-card/sales-prices-section/FixedPriceDrawer'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { store } from 'store'
import { useGetProducersQuery } from 'store/api/boltApi'
import { updateOpportunityId, setSelectedProducer } from 'store/app/slice'
import { CustomerFlows, InputGroupKeys, SimulationFieldKeys } from 'store/customer/enums'
import { goToStep, resetCustomerStore, updateFixedPrices, updateInput, updateSimulatedPropositions } from 'store/customer/slice'
import { SimulationType } from 'store/marketing/types'
import { Language } from 'types/language'
import { filterAndSortProducers } from 'utils/producers'
import { routes } from 'utils/routes'
import styles from './SimulationTypeToggle.module.scss'
import { useTranslation } from 'react-i18next'
import { SimulationSteps } from 'features/simulation/types'

const SimulationTypeToggle = () => {
  // Next Router
  const router = useRouter()
  const { locale, pathname } = router

  // Local state
  const [showSimSelection, setShowSimSelection] = useState<boolean>(pathname === '/')
  const [showFixedPriceDrawer, setShowFixedPriceDrawer] = useState<boolean>(false)

  // Redux store
  const { data: producers } = useGetProducersQuery()
  const dispatch = useStoreDispatch()
  const { simulationType, resetButton } = useStoreSelector((store) => store.marketing)
  const { chosenSimulationType } = useStoreSelector((store) => store.customer.inputs.simulation)

  // i18n
  const { t } = useTranslation(['common', 'seo'])

  /**
   * Handles the simulation type switch
   *
   * @param {SimulationType} desiredSimulationType
   */
  const handleSimulationSwitch = (desiredSimulationType: SimulationType) => {
    setShowSimSelection(false)

    if (desiredSimulationType === SimulationType.FIXED_AMOUNT) {
      setShowFixedPriceDrawer(true)
    }

    if (chosenSimulationType === SimulationType.FIXED_AMOUNT) {
      store.dispatch(updateFixedPrices(undefined))
    } else if (chosenSimulationType === SimulationType.PRICING_SIMULATION) {
      store.dispatch(updateSimulatedPropositions({ propositions: null })) //default value of simulatedPropositions is null
    }

    //update store with chosen simulation type
    store.dispatch(
      updateInput({
        group: InputGroupKeys.SIMULATION,
        key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE,
        value: desiredSimulationType
      })
    )

    //if desired simulation type is pricing simulation, redirect to first step of pricing simulation
    if (desiredSimulationType === SimulationType.PRICING_SIMULATION) {
      router.push(routes(locale).simulation)
      store.dispatch(goToStep({ step: SimulationSteps.POSTAL_CODE, flow: CustomerFlows.SIMULATION }))
    }
  }

  /**
   * Handles the reset button onClick event
   */
  const handleOnResetButtonClick = async () => {
    // redirect to homepage
    await router.push(routes(locale).home)

    // reset opportunityId, customer store & selected producer
    dispatch(updateOpportunityId({ opportunityId: '' }))
    dispatch(resetCustomerStore())

    const defaultProducer = filterAndSortProducers({
      hideSoldOut: true,
      language: locale as Language,
      producers: Object.values(producers)
    })[0]
    dispatch(setSelectedProducer({ producerSlug: defaultProducer.slug }))
  }

  return (
    <div className={styles['reset-button']}>
      {resetButton && simulationType.length === 1 ? (
        <Button variant="tertiary" onClick={async () => await handleOnResetButtonClick()} leadingIcon="refresh">
          {t('optionsButton.reset')}
        </Button>
      ) : (
        <>
          <Button variant="secondary" onClick={() => setShowSimSelection(!showSimSelection)} leadingIcon="settings">
            {t('optionsButton.title', 'Opties')}
          </Button>
          {showSimSelection && (
            <Card className={styles['button-card']}>
              {Object.keys(SimulationType).map((key) => {
                if (simulationType.includes(SimulationType[key])) {
                  return (
                    <Button
                      key={key}
                      variant="tertiary"
                      leadingIcon="shuffle"
                      onClick={() => handleSimulationSwitch(SimulationType[key])}
                      disabled={chosenSimulationType === SimulationType[key]}
                    >
                      {t(`optionsButton.${key.toLowerCase()}`)}
                    </Button>
                  )
                }
              })}
              {resetButton && (
                <Button variant="tertiary" onClick={async () => await handleOnResetButtonClick()} leadingIcon="refresh">
                  {t('optionsButton.reset')}
                </Button>
              )}
            </Card>
          )}
        </>
      )}
      <FixedPriceDrawer isOpen={showFixedPriceDrawer} onRequesteClose={() => setShowFixedPriceDrawer(false)} />
    </div>
  )
}

export default SimulationTypeToggle
